const loginLanguage = {
  "zh":{
    "login_account": "登录账号",
    "email": "邮箱",
    "phone": "手机",
    "forget_password": "忘记密码",
    "login": "登录",
    "email_error": "邮箱格式输入错误",
    "password_error": "请输入8位及以上的密码",
    "email_tips":"请输入您的邮箱地址",
    "phone_tips":"请输入您的手机号",
    "password_tips":"请输入8位及以上的密码",
    "update_password_tips":"修改密码及注册需在AiPaper设备上操作",
    "return_login":"返回登录",
    "password_reqired":"密码必填",
    "email_reqired":"邮箱必填",
    "phone_reqired":"手机号必填",
    "please_choose":"区号",
    "please_choose_area":"请选择区号",
    "sms_login":"验证码登录",
    "password_login":"密码登录",
    "verification_tips":"请输入短信验证码",
    "enter_password_tips":"请输入您的密码",
    "verification_code":"获取验证码",
    "reSend": "重发",
    "sending":"发送中"
  },
  "tw": {
    "login_account": "登入帳號",
    "email": "信箱",
    "phone": "手機",
    "forget_password": "忘記密碼",
    "login": "登入",
    "email_error": "信箱格式輸入錯誤",
    "password_error": "請輸入8位及以上的密碼",
    "email_tips": "請輸入您的信箱地址",
    "phone_tips": "請輸入您的手機號",
    "password_tips": "請輸入8位及以上的密碼",
    "update_password_tips": "修改密碼及註冊需在AiPaper設備上操作",
    "return_login": "返回登入",
    "password_reqired": "密碼為必填項",
    "email_reqired": "信箱為必填項",
    "phone_reqired": "手機號為必填項",
    "please_choose": "區號",
    "please_choose_area": "請選擇區號",
    "sms_login": "驗證碼登入",
    "password_login": "密碼登入",
    "verification_tips": "請輸入簡訊驗證碼",
    "enter_password_tips": "請輸入您的密碼",
    "verification_code": "獲取驗證碼",
    "reSend": "重新發送",
    "sending": "發送中"
},
    "uk":{
      "login_account": "Login Account",
      "email": "Email",
      "phone": "Phone",
      "forget_password": "Forget Password",
      "login": "Login",
      "email_error": "Email Error",
      "password_error": "Please enter a password of 8 digits or more",
      "email_tips":"Please enter your email",
      "phone_tips":"Please enter your phone",
      "password_tips":"Please enter a password of 8 digits or more",
      "update_password_tips":"Update password or register on AiPaper",
      "return_login":"Back",
      "password_reqired":"Password Reqired",
      "email_reqired":"Email Reqired",
      "phone_reqired":"Phone Reqired",
      "please_choose":"Code",
      "please_choose_area":"Please choose area code",
      "sms_login":"Verification Code Login",
      "password_login":"Password Login",
      "verification_tips":"Please enter sms code",
      "enter_password_tips":"Please enter your password",
      "verification_code":"Send",
      "reSend": "ReSend",
      "sending":"sending"
    },
    "us":{
      "login_account": "Login Account",
      "email": "Email",
      "phone": "Phone",
      "forget_password": "Forget Password",
      "login": "Login",
      "email_error": "Email Error",
      "password_error": "Please enter a password of 8 digits or more",
      "email_tips":"Please enter your email",
      "phone_tips":"Please enter your phone",
      "password_tips":"Please enter a password of 8 digits or more",
      "update_password_tips":"Update password or register on AiPaper",
      "return_login":"Back",
      "password_reqired":"Password Reqired",
      "email_reqired":"Email Reqired",
      "phone_reqired":"Phone Reqired",
      "please_choose":"Code",
      "please_choose_area":"Please choose area code",
      "sms_login":"Verification Code Login",
      "password_login":"Password Login",
      "verification_tips":"Please enter sms code",
      "enter_password_tips":"Please enter your password",
      "verification_code":"Send",
      "reSend": "ReSend",
      "sending":"sending"
    },

    "de":{
      "login_account": "Konto anmelden",
      "email": "E-Mail",
      "phone": "Telefon",
      "forget_password": "Passwort vergessen",
      "login": "Anmelden",
      "email_error": "E-Mail-Fehler",
      "password_error": "Bitte geben Sie ein 8-stelliges Passwort oder mehr ein",
      "email_tips": "Bitte E-Mail-Adresse eingeben",
      "phone_tips": "Bitte Telefonnummer eingeben",
      "password_tips": "Bitte geben Sie ein 8-stelliges Passwort oder mehr ein",
      "update_password_tips": "Passwort ändern oder bei AiPaper registrieren",
      "return_login": "Zurück",
      "password_reqired": "Passwort erforderlich",
      "email_reqired": "E-Mail erforderlich",
      "phone_reqired": "Telefon erforderlich",
      "please_choose": "Bitte Ordner oder Datei auswählen",
      "please_choose_area": "Bitte Vorwahl wählen",
      "sms_login": "Bestätigungscode Anmeldung",
      "password_login": "Passwort Login",
      "verification_tips": "Bitte SMS-Code eingeben",
      "enter_password_tips": "Bitte geben Sie Ihr Passwort ein",
      "verification_code": "Abschicken",
      "reSend": "Erneut senden",
      "sending": "Abschicken",
    },
    "fr":{
      "login_account": "Compte de connexion",
      "email": "e-mail",
      "phone": "Téléphone",
      "forget_password": "Oubli du mot de passe",
      "login": "Connexion",
      "email_error": "Erreur d'e-mail",
      "password_error": "Veuillez entrer un mot de passe à 8 chiffres et plus",
      "email_tips": "Veuillez saisir votre e-mail",
      "phone_tips": "Veuillez saisir votre téléphone",
      "password_tips": "Veuillez entrer un mot de passe à 8 chiffres et plus",
      "update_password_tips": "Mettre à jour le mot de passe ou s'inscrire sur AiPaper",
      "return_login": "Retour",
      "password_reqired": "Mot de passe requis",
      "email_reqired": "E-mail requis",
      "phone_reqired": "Téléphone requis",
      "please_choose": "Veuillez choisir un dossier ou un fichier",
      "please_choose_area": "Veuillez choisir l'indicatif régional",
      "sms_login": "Code de vérification Connexion",
      "password_login": "Mot de passe Connexion",
      "verification_tips": "Veuillez entrer le code sms",
      "enter_password_tips": "Veuillez entrer votre mot de passe",
      "verification_code": "Envoyer",
      "reSend": "Renvoyer",
      "sending": "envoi"
    },
    "es":{
      "login_account": "Cuenta de inicio de sesión",
      "email": "Correo electrónico",
      "phone": "Teléfono",
      "forget_password": "Contraseña olvidada",
      "login": "Iniciar sesión",
      "email_error": "Error de correo electrónico",
      "password_error": "Introduzca una contraseña de 8 dígitos o más",
      "email_tips": "Por favor, introduce tu email",
      "phone_tips": "Por favor, introduce tu teléfono",
      "password_tips": "Introduzca una contraseña de 8 dígitos o más",
      "update_password_tips": "Actualiza tu contraseña o regístrate en AiPaper",
      "return_login": "Volver",
      "password_reqired": "Contraseña Requerida",
      "email_reqired": "Correo Electrónico Requerido",
      "phone_reqired": "Teléfono Requerido",
      "please_choose": "Elige carpeta o archivo",
      "please_choose_area": "Por favor, elige el código de área",
      "sms_login": "Código de verificación Inicio de sesión",
      "password_login": "Contraseña de Inicio de Sesión",
      "verification_tips": "Por favor, introduce el código sms",
      "enter_password_tips": "Introduzca su contraseña",
      "verification_code": "Enviar",
      "reSend": "Reenviar",
      "sending": "enviando"
    },
    "ko":{
      "login_account": "계정 로그인",
      "email": "이메일",
      "phone": "전화",
      "forget_password": "비밀번호 잊음",
      "login": "로그인",
      "email_error": "이메일 오류",
      "password_error": "비밀번호는 6~16자여야 합니다",
      "email_tips": "이메일을 입력하세요",
      "phone_tips": "전화를 입력하세요",
      "password_tips": "비밀번호를 입력하세요",
      "update_password_tips": "비밀번호 업데이트 또는 AiPaper 에 등록",
      "return_login": "뒤로",
      "password_reqired": "비밀번호 필요",
      "email_reqired": "이메일 필요",
      "phone_reqired": "전화 필요",
      "please_choose": "폴더 또는 파일을 선택하세요",
      "please_choose_area": "국가 코드를 선택하세요",
      "sms_login": "인증 코드 로그인",
      "password_login": "비밀번호 로그인",
      "verification_tips": "SMS 코드를 입력하세요",
      "enter_password_tips": "암호를 입력하십시오",
      "verification_code": "전송",
      "reSend": "재전송",
      "sending": "전송 중"
    },
    "nl":{
      "login_account": "Account inloggen",
      "email": "E-mail",
      "phone": "Telefoon",
      "forget_password": "Wachtwoord vergeten",
      "login": "Inloggen",
      "email_error": "E-mail fout",
      "password_error": "Wachtwoord vereist 6-16 cijfers",
      "email_tips": "Voer je e-mailadres in",
      "phone_tips": "Voer je telefoon in",
      "password_tips": "Voer je wachtwoord in",
      "update_password_tips": "Update wachtwoord of registreer op AiPaper",
      "return_login": "Terug",
      "password_reqired": "Wachtwoord vereist",
      "email_reqired": "E-mail verplicht",
      "phone_reqired": "Telefoon vereist",
      "please_choose": "Kies een map of bestand",
      "please_choose_area": "Kies netnummer",
      "sms_login": "Verificatiecode Inloggen",
      "password_login": "Wachtwoord inloggen",
      "verification_tips": "Voer sms-code in",
      "enter_password_tips": "Voer uw wachtwoord in",
      "verification_code": "Verstuur",
      "reSend": "Opnieuw verzenden",
      "sending": "verzenden"
    },
    "pt":{
      "login_account": "Entrar na Conta",
      "email": "E-mail",
      "phone": "Telefone",
      "forget_password": "Esqueceu a Senha",
      "login": "Login",
      "email_error": "Erro de E-mail",
      "password_error": "A senha deve ter de 6 a 16 dígitos",
      "email_tips": "Por favor, insira seu e-mail",
      "phone_tips": "Por favor, insira seu telefone",
      "password_tips": "Por favor, insira sua senha",
      "update_password_tips": "Atualize a senha ou registre-se no AiPaper",
      "return_login": "Voltar",
      "password_reqired": "Senha Obrigatória",
      "email_reqired": "E-mail Obrigatório",
      "phone_reqired": "Telefone Obrigatório",
      "please_choose": "Por favor, escolha uma pasta ou arquivo",
      "please_choose_area": "Por favor, escolha o código de área",
      "sms_login": "Login com Código de Verificação",
      "password_login": "Login com Senha",
      "verification_tips": "Por favor, insira o código SMS",
      "enter_password_tips": "Por favor introduza a sua senha",
      "verification_code": "Enviar",
      "reSend": "Reenviar",
      "sending": "enviando"
    },
    "ja":{
      "login_account": "アカウントログイン",
      "email": "メール",
      "phone": "電話",
      "forget_password": "パスワードを忘れた場合",
      "login": "ログイン",
      "email_error": "メールエラー",
      "password_error": "パスワードは6-16桁が必要です",
      "email_tips": "メールアドレスを入力してください",
      "phone_tips": "電話番号を入力してください",
      "password_tips": "パスワードを入力してください",
      "update_password_tips": "AiPaperでパスワードを更新または登録してください",
      "return_login": "戻る",
      "password_reqired": "パスワードが必要です",
      "email_reqired": "メールアドレスが必要です",
      "phone_reqired": "電話番号が必要です",
      "please_choose": "フォルダーまたはファイルを選択してください",
      "please_choose_area": "国番号を選択してください",
      "sms_login": "認証コードでログイン",
      "password_login": "パスワードでログイン",
      "verification_tips": "SMSコードを入力してください",
      "enter_password_tips": "パスワードを入力してください",
      "verification_code": "送信",
      "reSend": "再送信",
      "sending": "送信中"
    },
    "it":{
      "login_account": "Account di Accesso",
      "email": "Email",
      "phone": "Telefono",
      "forget_password": "Password Dimenticata",
      "login": "Accedi",
      "email_error": "E-mail Errore",
      "password_error": "Password richiesta da 6 a 16 cifre",
      "email_tips": "Inserisci la tua email",
      "phone_tips": "Inserisci il tuo telefono",
      "password_tips": "Inserisci la tua password",
      "update_password_tips": "Aggiorna password o registrati su AiPaper",
      "return_login": "Indietro",
      "password_reqired": "Password richiesta",
      "email_reqired": "Email richiesta",
      "phone_reqired": "Telefono richiesto",
      "please_choose": "Scegli cartella o file",
      "please_choose_area": "Scegli il prefisso",
      "sms_login": "Codice di verifica Accedi",
      "password_login": "Password Accedi",
      "verification_tips": "Inserisci il codice SMS",
      "enter_password_tips": "Inserisci la tua password",
      "verification_code": "Invia",
      "reSend": "Invia di nuovo",
      "sending": "Invio"
    },
    "ru": {
    "login_account": "Войти в аккаунт",
    "email": "Электронная почта",
    "phone": "Телефон",
    "forget_password": "Забыли пароль",
    "login": "Войти",
    "email_error": "Неправильный формат электронной почты",
    "password_error": "Введите пароль из 8 или более символов",
    "email_tips": "Введите ваш адрес электронной почты",
    "phone_tips": "Введите ваш номер телефона",
    "password_tips": "Введите пароль из 8 или более символов",
    "update_password_tips": "Изменение пароля и регистрация выполняются на устройстве AiPaper",
    "return_login": "Вернуться к входу",
    "password_reqired": "Пароль обязателен",
    "email_reqired": "Электронная почта обязательна",
    "phone_reqired": "Номер телефона обязателен",
    "please_choose": "Код региона",
    "please_choose_area": "Пожалуйста, выберите код региона",
    "sms_login": "Вход по коду подтверждения",
    "password_login": "Вход по паролю",
    "verification_tips": "Введите код подтверждения из SMS",
    "enter_password_tips": "Введите пароль",
    "verification_code": "Получить код подтверждения",
    "reSend": "Отправить повторно",
    "sending": "Отправка"
}
  }
  export {
    loginLanguage
  }